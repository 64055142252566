.mission {
    background: linear-gradient(180deg, #E3E5F5 -5.54%, #FFFFFF 100%);
    padding: 125px 0 125px 0
}
.mission__header{
    font-size: 64px;
    margin-bottom: 24px;
}
.mission__item:hover{
    background-color: #1C235D;
}
.mission__item:hover .mission__item__icon{
    background-color: #ED7900;
    color: #ffffff;
}
.mission__item:hover .mission__item__heading{
    color: #ffffff;
}
.mission__item:hover .mission__item__description{
    color: #ffffff;
}
.mission__item{
    padding: 32px;
    max-width: 400px;
    border-radius: 10px;
    margin: 0 auto 8px auto;
    height: 312px;
}
.mission__item__icon{
    margin-bottom: 24px;
    padding: 20px;
    background-color: #EBEDFF;
    color: #2E2E2E;
    border-radius: 5px;
}
.mission__item__heading{
    font-size: 24px;
    margin-bottom: 16px;
    color: #2E2E2E;
    font-weight: 700;
}
.mission__item__description{
    color: #808080;
    font-size: 16px;
    font-weight: 400;
    line-height: 36px;
}

// media query
@media (max-width: 480px) {
    .mission {
        padding: 80px 0 35px 0;
    }
    .mission__header{
        font-size: 26px;
        margin-bottom: 24px;
        text-align: center;
    }
    .mission__item{
        padding: 12px;
        max-width: 323px;
        border-radius: 10px;
        margin: 0 auto 8px auto;
        height: auto;
    }
    .mission__item__icon{
        margin-bottom: 16px;
        padding: 10px;
        background-color: #EBEDFF;
        color: #2E2E2E;
        border-radius: 5px;
    }
    .mission__item__heading{
        font-size: 16px;
        margin-bottom: 16px;
        color: #2E2E2E;
        font-weight: 700;
    }
    .mission__item__description{
        color: #808080;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }
}