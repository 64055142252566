.social-holder{
    // background-color:rgba(28, 35, 94, 0.5);
    // // background-color: rgba(237, 121, 0, 0.5);
    background: rgb(237,121,0);
    background: linear-gradient(0deg, rgba(237,121,0,1) 0%, rgba(133,78,47,1) 31%, rgba(28,35,94,1) 100%);
    border-radius: 10px;
    box-shadow: 0 5px 50px rgba(0,0,0,0.5);
    color: #ffffff;
    font-size: 30px;
    padding: 10px;
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    
}
.social{
    opacity: 1;
    display: flex;
    flex-direction: column;

    & .media-holder.open{
        height: 140px;
    }
    & .media-holder.close{
        height: 0px;
    }
    & .media-holder{
        overflow: hidden;
        transition-duration: 0.5s;
    }
    
    &__media > i{
        font-size: 28px;
        margin-left: 1.5px;
    }

    &__profile{
        width: 28px;
    }
    &__toggle > i{
        font-size:28px;
        margin-left: 5px;
    }
}