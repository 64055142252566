.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-logo{
    height: 28px;
    width: 28px;
}
.nav-list{
    list-style-type: none;
    min-width: 445px;
    justify-content: space-between;
    .nav-item{
        border-bottom: 4px transparent solid;
        &:hover{
            border-bottom: 4px #ED7900 solid;
        }
    }
    .nav-item > a{
        text-decoration: none;
        color: inherit;
    }
}
.fixed-header-bg{
background-color: #FFFFFF;
color: #1C235D;
transition-duration: 0.4s;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.fixed-header{
    position: fixed;
    width: 100%;
    z-index: 10;
    transition-duration: 0.4s;
}
.primary-btn{
    border-radius: 8px;
    padding: 12px 24px;
    background-color: #ED7900;
    color: #ffffff;
}

.mobi-donate-btn{
    display: none;
}
.payment-model-holder{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.blur-bg{
    position: absolute;
    inset: 0;
    background-color: #0000001a;
    backdrop-filter: blur(4px);
    z-index: -1;
}
.banner-container {
    position: relative;
}
.banner-close {
    position: absolute;
    top: -13px;
    right: -7px;
    color: black;
    font-size: 24px;
}
.banner-model {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #ffffff;
    max-width: 62rem;
    margin: 8% auto 8% auto;
    padding: 30px;
    border-radius: 10px;
}
.banner-mobile {
    display: none;
}
.banner-web {
    display: block;
}

// media query
@media (max-width: 480px) {
    .header{
        display: block;
    }
    .desk-donate-btn{
        display: none;
    }
    .mobi-donate-btn{
        display: unset;
    }
    .header-logo-center{
        justify-content: space-between;
        align-items: center;
    }
    .nav-list{
        list-style-type: none;
        min-width: auto;
        justify-content: unset;
        flex-direction: column;
        text-align: center;
        max-width: 100px;
        margin: 0 auto;
        .nav-item{
            border-bottom: 4px transparent solid;
            margin: 10px 0 ;
            &:hover{
                border-bottom: 4px #ED7900 solid;
            }
        }
        .nav-item > a{
            text-decoration: none;
            color: inherit;
        }
    }
    .nav-bar__holder{
        display: none;
    }
    .nav-bar__holder.active{
        display: block;
    }
    .hamburger-menu{
        display: flex;
        transition-duration: 0.4s;

        & span{
            height: 2px;
            margin-bottom: 5px;
            width: 25px;
        }
    }
    .menu-icon{
        font-size: 26px;
    }
    .banner-model {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 23rem;
    }
    .banner-mobile {
        display: block;
    }
    .banner-web {
        display: none;
    }
}