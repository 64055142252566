.container {
    max-width: 1232px;
    min-width: 335px;
    padding-inline: 16px;
    margin: 0 auto;
    overflow: hidden;
}
.secondary-btn {
    border-radius: 4px;
    padding: 12px 24px;
    background-color: #3647D3;
    color: #ffffff;
  }
  .hide-scrollbar{
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
.app{
    // font-family: 'Inter';
}
/* Media Query for Mobile Devices */
@media (max-width: 480px) {}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {}

/* Media Query for Large screens */
@media (min-width: 1281px) {}