.footer {
    background: #f5f6ff;
    padding: 50px 0;
    color: #1c235e
  }
  .join-us {
    padding-bottom: 40px;
  }
  .about-header{
    margin-bottom: 18px;
    img {
      width: 50px;
      height: 50px;
    }
    h3{
      font-size: 22px;
    font-weight: 700;
    margin-left: 14px;
    }
  }
  .about-desc {
    max-width: 600px;
    line-height: 24px;
    text-align: justify;
  }
  .about-contact{
    justify-content: space-between;
  }
  .contact{
    table caption {
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      margin-bottom: 20px;
    }
    table{
      width: 100%;
    }
    table td {
      padding-bottom: 10px;
    }
    .contact-icon{
      padding-right: 10px;
    }
  }
  .network-item{
    padding: 6px;
    border: 1px solid #1c235e;
    border-radius: 50px;
    font-size: 22px;
    text-align: center;
    width: 38px;
    height: 38px;
    margin-inline: 8px;
  }
  .network-holder{
    display: flex;
    justify-content: center;
  }
  .footer-border{
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(28, 35, 94, 0.3);
  }
  .copy-right{
    font-size: 12px;
    padding-top: 20px;
  }
  // media query
@media (max-width: 480px) {
  .about-contact{
    flex-direction: column;
  }
  .about-header{
    justify-content: center;
  }
  .about{
    margin-bottom: 20px;
  }
  .contact table caption{
    text-align: center;
  }
}