.hero {
    background-color: #1c235d99;
    height: 100vh;
    color: #ffffff;
    position: relative;
}
.background-video{
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
    object-fit: cover;
    z-index: -1;
}
.hero-heading {
    font-weight: 700;
    margin: 0 auto 24px auto;
    font-size: 41px;
    line-height: 60px;
}

.hero-desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    font-size: 600;
    margin-bottom: 32px;
}

.hero-btn {
    margin-left: 26px;
}

.hero-section {
    top: 50%;
    transform: translateY(-50%);

    &__text {
        max-width: 660px;
        margin-bottom: 25px;
        text-align: left;
    }

    &__img {
    }
}


.hero__img {
    position: absolute;
    border-radius: 50%;

    &.img1 {
        height: 400px;
        width: 400px;
        top: -23%;
        left: 108%;
    }

    &.img2 {
        width: 240px;
        height: 240px;
        right: -90%;
        top: 57%;
    }

    &.img3 {
        width: 200px;
        height: 200px;
        top: 80%;
        right: -50%;
    }
}

.typewriter span {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid #ffffff; /* The typwriter cursor */
    white-space: nowrap ; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 1px; /* Adjust as needed */
    animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
    color: #ED7900;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 42% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #ffffff; }
  }


// media query
@media (max-width: 480px) {
    .hero-heading {
        font-size: 28px;
        line-height: 42px;
        text-align: center;
        width: 294px;

    }

    .hero-desc {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 32px;
        width: 326px;
    }

    .hero-btn {
        margin-left: 26px;
    }

    .hero-btn__holder {
        text-align: center;
    }

    .hero-section {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &__text {
            width: auto;
            margin-bottom: 25px;
            text-align: center;
        }

        &__img {}
    }

    .hero-section__img {
        position: relative;
        height: 240px;

    }

    .hero__img {
        position: absolute;
        border-radius: 50%;

        &.img1 {
            height: 156px;
            width: 156px;
            top: 0;
            left: 100px;
        }

        &.img2 {
            width: 93.75px;
            height: 93.75px;
            right: -20px;
            top: 100px;
        }

        &.img3 {
            width: 78.12px;
            height: 78.13px;
            top: 164px;
            right: 66px;
        }
    }
    @keyframes typing {
        from { width: 0 }
        to { width: 80% }
      }
}