.latest-blogs {
    background: linear-gradient(180deg, #e3e5f5 -5.54%, #ffffff 100%);
    padding: 125px 0 125px 0
  }
  .latest-blogs__heading{
      font-size: 64px;
      margin-bottom: 34px;
  }
  .latest-blogs__items__holder {
      display: flex;
      align-items: center;
      overflow: auto;
      &::-webkit-scrollbar {
          display: none;
      }
  }
  .latest-blogs__item{
      width: 400px;
      height: 471px;
      background: #FFFFFF;
      margin: 0 16px;
      border-radius: 16px;
      &-img{
          width: 100%;
          height: 177px;
          object-fit: cover;
          border-radius: 8px;
      }
      &-text-bold{
          line-height: 24px;
      }
      &-text-small{
          font-size: 14px;
          line-height: 22px;
          color: #666666;
      }
  }
  @media (max-width: 480px) {
    .latest-blogs {
        background: linear-gradient(180deg, #e3e5f5 -5.54%, #ffffff 100%);
        padding: 80px 0 50px 0;
      }
      .latest-blogs__heading{
          font-size: 26px;
          text-align: center;
      }
      .latest-blogs__items__holder {
          display: flex;
          align-items: center;
          overflow: auto;
          &::-webkit-scrollbar {
              display: none;
          }
      }
      .latest-blogs__item{
          min-width: 270px;
          height: 471px;
          background: #FFFFFF;
          margin: 0 16px;
          border-radius: 16px;
          &-img{
              width: 100%;
              height: 177px;
              object-fit: cover;
              border-radius: 8px;
          }
          &-text-bold{
              line-height: 24px;
          }
          &-text-small{
              font-size: 14px;
              line-height: 22px;
              color: #666666;
          }
      }
  }