.help-us-mobi{
    // display: none;
}
.help-us{
    display: block;
}
.help-us{
    background-image: url(../../assets/logo.png);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
  
// .helpus__text{
//     color: #ffffff;
//     background: #1c235e;
//     overflow: hidden;
//     clip-path: polygon(0 0, 78% 0, 100% 100%, 0% 100%);
//     height: 100vh;
//     width: 750px;
// }
.helpus__parallax {
    background-image: url(../../assets/joinUsBg.jpg);
    height: 70vh;
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}  

.helpus__text{
    padding: 40px 0; 
    color: #ffffff;
    background: #1c235e;
    clip-path: none;
    width: auto;
    height: auto;
    // border-top-left-radius: 20px;
    // border-top-right-radius: 20px;
    overflow: hidden;
}

.helpus__heading{
    font-size:64px;
    padding-bottom: 20px;
    line-height: 88px;
}
.helpus__caption{
    padding-bottom: 30px;
    font-size: 16px;
    line-height: 32px;
    font-size: 400;
}
.helpus__caption__action{
    max-width: 460px;
    margin: 0 auto;
}
.become-a-monthly-donor{
    margin-right: 20px;
}
.helpus__text__content{
    position: absolute;
width : 600px;
top: 50%;
transform: translateY(-50%);

}
.primary-btn{
    border-radius: 8px;
    padding: 12px 24px;
    background-color: #ED7900;
    color: #ffffff;
}
.primary-btn__outline{
    border-radius: 8px;
    border: 1px solid #ffffff;
    padding: 10px 24px;
    background-color: transparent;
    color: #ffffff;
}
.payment-model-holder{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 480px) {
    .help-us{
        display: none;
    }
    .help-us-mobi{
        display: block;
    }
    // .helpus__parallax {
    //     height: 50vh;
    //     background-attachment: fixed;
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    // }   
    .helpus__text{
        padding: 40px 0; 
        color: #ffffff;
        background: #1c235e;
        clip-path: none;
        width: auto;
        height: auto;
        // border-top-left-radius: 20px;
        // border-top-right-radius: 20px;
        overflow: hidden;
    }
    .helpus__heading{
        font-size:28px;
        padding-bottom: 20px;
        line-height: 42px;
    }
    .helpus__caption{
        padding-bottom: 30px
    }
    .helpus__caption__action{
        max-width: 350px;
        margin: 0 auto;
        flex-direction: column;
    }
    .become-a-monthly-donor{
        margin-bottom: 20px;
        margin-right: 0;
    }
    .primary-btn{
        border-radius: 8px;
        padding: 12px 24px;
        background-color: #ED7900;
        color: #ffffff;
    }
    .primary-btn__outline{
        border-radius: 8px;
        border: 1px solid #ffffff;
        padding: 10px 24px;
        background-color: transparent;
        color: #ffffff;
    }
}
.blur-bg{
    position: absolute;
    inset: 0;
    background-color: #0000001a;
    backdrop-filter: blur(4px);
    z-index: -1;
}
