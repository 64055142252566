.projects {
    padding-bottom: 20px;
}

.projects__heading {
    font-size: 64px;
    margin-bottom: 24px
}

.project__item__holder {
    display: flex;
    align-items: center;
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
}

.project__item {
    min-width: 267px;
    height: 259px;
    border-radius: 10px;
    position: relative;
    margin: 0 16px;
    background: red;
    overflow: hidden;
}

.project__item-img {
    width: 267px;
    height: 259px;
}

.project_desc {
    background-color: #1C235D;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 12px 16px;
    color: #ffffff;
}

.project_desc__link {
    font-size: 14px;
    margin-bottom: 12px;
}

.project_desc__caption {}


@media (max-width: 480px) {
    .projects {
        padding-bottom: 20px;
    }

    .projects__heading {
        font-size: 26px;
        margin-bottom: 24px
    }

    .project__item__holder {
        display: flex;
        align-items: center;
        overflow: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .project__item {
        min-width: 267px;
        height: 259px;
        border-radius: 10px;
        position: relative;
        margin: 0 16px;
        background: red;
        overflow: hidden;
    }

    .project__item-img {
        width: 267px;
        height: 259px;
    }

    .project_desc {
        background-color: #1C235D;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 12px 16px;
        color: #ffffff;
    }

    .project_desc__link {
        font-size: 14px;
        margin-bottom: 12px;
    }

    .project_desc__caption {}
}