.payment-model{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #ffffff;
    max-width: 27rem;
    margin: 8% auto 8% auto;
    padding: 30px;
    border-radius: 10px;

    h2{
        font-size: 41px;
        margin: 10px 0 16px 0;
        text-align: center;
        font-weight: 600;
        color: #1c235e;

        span{
            color: #ED7900;
        }
    }
}
.payment-model__close {
    text-align: center;

    button{
        color: tomato;
        border: 1px solid tomato;
        padding: 7px 20px;
        border-radius: 50px;

        &:hover{
            color: #ffffff;
            background-color: tomato;
        }
    }

}
.qr-holder{
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
}
.payment__upi-id{
    font-size: 16px;
    margin-bottom: 25px;
    color: #1c235e;
}
.account-details{
    font-size: 14px;
    &__label{
        color: #757575
    }

}
.qr-code{
    margin: 0 auto;
}
.upi-apps{
    display: flex;
    justify-content: space-around;
    margin-bottom: 25px;

    img {
        height: 16px;
    }
}
.payment-model__header{
    text-align: center;
    img{
        width: 50px;
        height: 50px;
        margin: 15px auto 10px auto;
    }
    p{
        font-size: 22px;
        font-weight: 600;
        color: #1c235e;
    }
}
.upi-header{
    color: #1c235e;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}


// media query
@media (max-width: 480px) {
    .payment-model{
        h2{
            font-size: 20px;
        }
    }
    .qr-holder{
        display: none;
    }
    .payment-model__header{

        p{
            font-size: 14px;
        }
    }

    .payment-model {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 23rem;
    }
    .upi-apps > :nth-child(-n+2){
        display: none;
    }
    .payment__upi-id.copy{
        background-color: #ECEFF1;
        border-radius: 5px;
        padding: 20px;
        position: relative;

        .copy-icon{
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);
            color: #1c235e;
            font-weight: bold;
        }
    }
    .copy-text{
        color: #ED7900;
        margin-top:10px;
    }
}